export const state = () => ({
  notification: null,
  menus: [],
  socials: [],
  categories: [],
  currency: '',
  logo: {},
  store: {},
  settings: {},
  countries: [],
  cities: [],
  locales: [],
  location: {
    city: {
      id: null,
      name: ''
    },
    latitude: null,
    longitude: null
  },
  country: {},
  localDeliveryOptions: {},
  localPaymentOptions: {},
  callcenter: ''
})

export const getters = {
  notification: state => state.notification,
  menus: state => state.menus,
  socials: state => state.socials,
  categories: state => state.categories,
  currency: state => state.currency,
  logo: state => state.logo,
  store: state => state.store,
  settings: state => state.settings,
  countries: state => state.countries,
  cities: state => state.cities,
  locales: state => state.locales,
  location: state => state.location,
  country: state => state.country,
  localDeliveryOptions: state => state.localDeliveryOptions,
  localPaymentOptions: state => state.localPaymentOptions,
  callcenter: state => state.callcenter
}

export const mutations = {
  SET_NOTIFICATION: (state, notification) => { state.notification = notification },
  SET_NOTIFICATION_ACTIVITY: (state, active) => { state.notification.active = active },
  SET_MENUS: (state, menus) => { state.menus = menus },
  SET_SOCIALS: (state, socials) => { state.socials = socials },
  SET_CATEGORIES: (state, categories) => { state.categories = categories },
  SET_CURRENCY: (state, currency) => { state.currency = currency },
  SET_LOGO: (state, logo) => { state.logo = logo },
  SET_STORE: (state, store) => { state.store = store },
  SET_SETTINGS: (state, settings) => { state.settings = settings },
  SET_COUNTRIES: (state, countries) => { state.countries = countries },
  SET_CITIES: (state, cities) => { state.cities = cities },
  SET_LOCALES: (state, locales) => { state.locales = locales },
  SET_CITY: (state, city) => { state.location.city = city },
  SET_LOCATION: (state, location) => { state.location = location },
  SET_COUNTRY: (state, country) => { state.country = country },
  SET_LOCAL_DELIVERY_OPTIONS: (state, localDeliveryOptions) => { state.localDeliveryOptions = localDeliveryOptions },
  SET_LOCAL_PAYMENT_OPTIONS: (state, localPaymentOptions) => {
    Object.values(localPaymentOptions).forEach((store, index) => {
      store.map((option) => {
        option.modal = {
          isActive: false
        }
      })
    })

    state.localPaymentOptions = localPaymentOptions
  },
  SET_CALLCENTER: (state, callcenter) => { state.callcenter = callcenter }
}

export const actions = {
  async nuxtServerInit ({ commit }, { app, req }) {
    // // const ip = req.connection.remoteAddress || req.socket.remoteAddress
    // const ip = req.headers['x-forwarded-for']

    // const location = await app.$cacheFetch(
    //   {
    //     key: `get:/client/${this.$config.storeId}/${this.$i18n.locale}/${this.$config.country}/location?ip=${ip}`,
    //     expire: this.$config.responseCacheLifetimeShort
    //   },
    //   async () => {
    //     return await app.$axios.$get(`${this.$i18n.locale}/${this.$config.country}/location?ip=${ip}`)
    //   }
    // )

    const cache = await app.$cacheFetch(
      {
        key: `get:/client/${this.$config.storeId}/${this.$i18n.locale}/${this.$config.country}/app`,
        expire: this.$config.responseCacheLifetime
      },
      async () => {
        return await app.$axios.$get(`${this.$i18n.locale}/${this.$config.country}/app`)
      }
    )

    commit('SET_NOTIFICATION', cache.notification)
    commit('SET_MENUS', cache.menus)
    commit('SET_SOCIALS', cache.socials)
    commit('SET_CATEGORIES', cache.menus.catalog)
    commit('SET_CALLCENTER', cache.callcenter)
    commit('modal/SET_EMAIL_TABS', cache.email)
    // commit('SET_LOCATION', location.data)
    commit('SET_COUNTRY', cache.country)
    commit('SET_CURRENCY', cache.currency)
    commit('SET_LOGO', cache.logo)
    commit('SET_STORE', cache.store)
    commit('SET_SETTINGS', cache.settings)
    commit('SET_CITIES', cache.cities)
    commit('SET_LOCALES', cache.locales.filter(locale => this.$i18n.locales.find(i18nLocale => locale.code === i18nLocale.code)))
    commit('SET_COUNTRIES', cache.countries)
    commit('support/SET_AI', cache.ai)
    commit('support/SET_CONTACTS', cache.support.contacts)
    commit('support/SET_SCHEDULE', cache.support.schedule)
    commit('support/SET_CHAT_DEPARTMENTS', cache.support.chat.departments)
    commit('support/SET_TICKET_DEPARTMENTS', cache.support.ticket.departments.filter(department => cache.email.includes(department.type)))
    commit('schema.org/SET_STORE', cache.schemaOrg.store)
  },

  setCity ({ commit }, city) {
    commit('SET_CITY', city)
  },

  setLocation ({ commit }, location) {
    commit('SET_LOCATION', location)
  },

  setNotificationActivity ({ commit }, active) {
    commit('SET_NOTIFICATION_ACTIVITY', active)
  }
}
