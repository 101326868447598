export const state = () => ({
  recent: {
    id: null,
    items: []
  }
})

export const getters = {
  recent: state => state.recent
}

export const mutations = {
  SET_RECENT: (state, recent) => {
    if ('id' in recent) {
      state.recent.id = recent.id
    }

    if ('items' in recent) {
      state.recent.items = recent.items
    }
  },
  ADD_TO_RECENT: (state, item) => {
    const found = state.recent.items.find(i => i.id === item.id && i.class === item.class)

    if (!found) {
      state.recent.items.unshift(item)
    } else {
      state.recent.items.sort((x, y) => x === found ? -1 : y === found ? 1 : 0)
    }
  },
  SAVE_RECENT_ID_TO_LOCALSTORAGE: (state) => {
    window.localStorage.setItem('recent', JSON.stringify({
      id: state.recent.id
    }))
  }
}

export const actions = {
  setRecent ({ commit }, recent) {
    commit('SET_RECENT', recent)
  },
  async addToRecent ({ commit, state, rootState }, item) {
    const country = rootState.country

    try {
      const recent = await this.$axios.$post(`${this.$i18n.locale}/${country.iso_code}/recent/${state.recent.id}/items`, item, { progress: false })

      commit('ADD_TO_RECENT', recent.data)
    } catch (e) {
      try {
        const recent = await this.$axios.$get(`${this.$i18n.locale}/${country.iso_code}/recent/${state.recent.id}`, { progress: false })

        commit('SET_RECENT', recent.data)
      } catch (e) {
        const recent = await this.$axios.$post(`${this.$i18n.locale}/${country.iso_code}/recent`, state.recent, { progress: false })

        commit('SET_RECENT', recent.data)
      }

      const recentItem = await this.$axios.$post(`${this.$i18n.locale}/${country.iso_code}/recent/${state.recent.id}/items`, item, { progress: false })

      commit('ADD_TO_RECENT', recentItem.data)
    }

    // if (rootState.auth.loggedIn) {
    //   rootState.auth.fetchUser()
    // }
  },
  async syncRecent ({ commit, state, rootState }) {
    const country = rootState.country

    if (state.recent.id === null) {
      await this.$axios.$post(`${this.$i18n.locale}/${country.iso_code}/recent`, state.recent, { progress: false })
        .then((res) => {
          commit('SET_RECENT', res.data)
          commit('SAVE_RECENT_ID_TO_LOCALSTORAGE')
        })
    } else {
      try {
        await this.$axios.$get(`${this.$i18n.locale}/${country.iso_code}/recent/${state.recent.id}`, { progress: false })
          .then((res) => {
            commit('SET_RECENT', res.data)
            commit('SAVE_RECENT_ID_TO_LOCALSTORAGE')
          })
      } catch (e) {
        await this.$axios.$post(`${this.$i18n.locale}/${country.iso_code}/recent`, state.recent, { progress: false })
          .then((res) => {
            commit('SET_RECENT', res.data)
            commit('SAVE_RECENT_ID_TO_LOCALSTORAGE')
          })
      }
    }

    // if (rootState.auth.loggedIn) {
    //   rootState.auth.fetchUser()
    // }
  }
}
