import Vue from 'vue'
import { ConfigProgrammatic, Button, Datepicker, Datetimepicker, Field, Loading, Table, Tooltip } from 'buefy'

Vue.use(Button)
Vue.use(Datepicker)
Vue.use(Datetimepicker)
Vue.use(Field)
Vue.use(Loading)
Vue.use(Table)
Vue.use(Tooltip)
ConfigProgrammatic.setOptions({
  css: false,
  materialDesignIcons: false,
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas'
})
